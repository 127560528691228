export default [
  {
    field: 'movement',
    label: 'Mouvement artistique',
  },
  {
    field: 'creator',
    label: 'Artiste',
  },
  /*
  {
    field: 'main_subject',
    label: 'Sujet principal',
  },
  {
    field: 'depicts',
    label: 'Représente'
  },
  */
]
