import React, { useState } from 'react';

export default (props) => {
  // const [isOpened, setIsOpened] = useState(props.openByDefault);
  const contentClass = (props.isOpened) ? 'facet-content opened' : 'facet-content closed';
  const wrapperClass = (props.isOpened) ? 'facet-wrapper opened' : 'facet-wrapper closed';
  return (
    <div className={wrapperClass}>
      <div className="facet-label" onClick={props.toggle}>
        {props.label}
        {!props.isOpened && <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M24 24H0V0h24v24z" fill="none" opacity=".87"/><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z"/></svg>}
        {props.isOpened && <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14l-6-6z"/></svg>}
      </div>
      <div className={contentClass}>{props.children}</div>
    </div>
  )
}
