import React, { useRef, useEffect, useState } from 'react';
import OpenSeaDragon from "openseadragon";
import tools from './tools'

import {
  Facet
  , SearchProvider
  , SearchBox
  , Results
  , Paging
  , Sorting
  , WithSearch
} from "@elastic/react-search-ui";

import {
  Layout
} from "@elastic/react-search-ui-views";

import "@elastic/react-search-ui-views/lib/styles/styles.css";

import Masonry from 'react-masonry-component';

import MultiCheckboxFacet from './components/MultiCheckboxFacet'
import SortOptions from './SortOptions'
import buildRequest from "./buildRequest";
import runRequest from "./runRequest";
import applyDisjunctiveFaceting from "./applyDisjunctiveFaceting";
import buildState from "./buildState";
import facetList from "./facetList";
import SearchBoxInputView from './components/SearchBoxInputView'
import Dropdown from './components/Dropdown'

import Loader from './components/Loader'

function getLabel(fieldKey) {
  return facetList.reduce((acc, facet) => {
    if (facet.field === fieldKey) {
      acc = facet.label;
    }
    return acc;
  });
}

const masonryOptions = {
  itemSelector: '.result',
  columnWidth: '.grid-sizer',
  itemSelector: '.grid-item',
  percentPosition: true,
  gutter: '.gutter-sizer',
};

const imagesLoadedOptions = { background: '.my-bg-image-el' }

const elasticConfig = {
  initialState: {
    resultsPerPage: 50,
    filters: [{"field":"creator","values":["Jean-Baptiste Camille Corot [Q148475]"],"type":"any"}]
  },
  debug: false,
  onSearch: async state => {
    const resultsPerPage = 100;
    // const { resultsPerPage } = state;
    const requestBody = buildRequest(state);
    // Note that this could be optimized by running all of these requests
    // at the same time. Kept simple here for clarity.
    const responseJson = await runRequest(requestBody);
    const responseJsonWithDisjunctiveFacetCounts = await applyDisjunctiveFaceting(
      responseJson,
      state,
      facetList.map(f => f.field)
    );
    return buildState(responseJsonWithDisjunctiveFacetCounts, resultsPerPage);
  },
  alwaysSearchOnInitialLoad: true,
}
  /*
      {currentImage &&
        <div className="fullscreen">
          <div className="media">
            <button onClick={() => setCurrentImage(null)}>X</button>
            <img src={currentImage} />
          </div>
        </div>
      }
          */

export default function App() {
  const hash = (window.location.hash) ? window.location.hash.substr(1) : null;
  const [currentImage, setCurrentImage] = useState(hash);
  const [currentDropdown, setCurrentDropdown] = useState('creator');
  const [viewer, setViewer] = useState( null);

  useEffect(() => {
    if (currentImage && viewer) {
      viewer.open([{
        type: 'image',
        url: currentImage
      }]);
    }
  }, [currentImage]);

  const InitOpenseadragon = () => {
    // viewer && viewer.destroy();
    setViewer(
      OpenSeaDragon({
        id: "openseadragon",
        zoomInButton:   "zoom-in",
        zoomOutButton:  "zoom-out",
        homeButton:     "home",
        fullPageButton: "toggle-fullscreen",
      })
    );
  };

  useEffect(() => {
    console.log('init');
    InitOpenseadragon();
    return () => {
        viewer && viewer.destroy();
    };
  }, []);

  const OpenSeaDragonClass = (currentImage) ? '' : 'hidden';

  return (
    <div>
      <div className="nav"><div className="nav-title">Les tableaux de Wikipedia</div></div>
      <div id="openseadragon-wrapper" className={OpenSeaDragonClass}>
        <div className="viewer-controls">
          <div class="viewer-close" href="#" onClick={() => setCurrentImage(null)}>×</div>
          <div id="zoom-in">+</div>
          <div id="zoom-out">-</div>
        </div>
        <div id="openseadragon"></div>
      </div>
      <SearchProvider config={elasticConfig}>
        <WithSearch mapContextToProps={(state) => (state)}>
          {(state) => {
            return (
              <div className="app">
                  <Layout
                    header={
                      <div></div>
                    }
                    bodyContent={
                      <>
                        {state.wasSearched &&
                          <>
                            {state.isLoading && <Loader />}
                            {!state.isLoading &&
                              <div className="results">
                                <Masonry
                                  className={'results-grid'} // default ''
                                  elementType={'div'} // default 'div'
                                  options={masonryOptions} // default {}
                                  disableImagesLoaded={false} // default false
                                  updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
                                  imagesLoadedOptions={imagesLoadedOptions} // default {}
                                >
                                  <div className="grid-sizer"></div>
                                  <div className="gutter-sizer"></div>
                                  {state.results.map(r => (
                                    <>
                                      {(r.id) &&
                                        <div className="grid-item result" key={r.id.raw} onClick={() => setCurrentImage(r.image_url.raw)}>
                                          <img src={tools.getThumbImageUrl(r.image_url.raw[0])} width={r.image_width.raw[0]} height={r.image_height.raw[0]} />
                                          <div>
                                            <div className="label-creator">
                                              {r.creator && r.creator.raw.map(c => (
                                                <span key={c}>{tools.getCleanedFilterValueDisplay(c)}</span>
                                              ))}
                                            </div>
                                            <div className="artwork-label">{r.label.raw}</div>
                                          </div>
                                        </div>
                                      }
                                    </>
                                  ))}
                                </Masonry>
                              </div>
                            }
                          </>
                        }
                      </>
                    }
                    bodyFooter={
                      <>
                        {!state.isLoading && <Paging />}
                      </>
                    }
                    sideContent={
                      <div className="side-wrapper">
                        <SearchBox
                          shouldClearFilters={false}
                          inputProps={{ placeholder: "Recherche" }}
                          autocompleteSuggestions={false}
                          inputView={SearchBoxInputView}
                        />
                        <div className="filter-badges">
                          {state.resultSearchTerm && <div className="badge">
                            « {state.resultSearchTerm} »
                            <span className="badge-clear" onClick={() => state.setSearchTerm("", {shouldClearFilters: false})} title={"Supprimer ce filtre"}>✕</span>
                          </div>}
                          {state.filters && state.filters.map((filter) => (
                            <>
                              {filter.values.map((val, i) => (
                                <div class="badge">
                                  {tools.getCleanedFilterValueDisplay(val)}
                                  <span className="badge-clear" onClick={() => state.removeFilter(filter.field, val)} title={"Supprimer ce filtre"}>✕</span>
                                </div>
                              ))}
                            </>
                          ))}
                         </div>
                        {false && <div className="button reset-search" onClick={() => { state.setSearchTerm("", {shouldClearFilters: true});state.setFilter('active_offers', true);}}><span>&#10005;</span>Réinitialiser la recherche et les filtres</div>}
                        {state.wasSearched && <>
                          {false && <Sorting label={"Trier par"} sortOptions={SortOptions} />}
                          {facetList.map((facet) => (
                            <Dropdown
                              label={facet.label}
                              key={facet.field}
                              // openByDefault={facet.field == 'creator' ? true : false}
                              isOpened={facet.field == currentDropdown}
                              toggle={() => setCurrentDropdown(facet.field)}
                            >
                              <Facet show={100} view={MultiCheckboxFacet}  filterType="any" field={facet.field} label={facet.label} isFilterable={true} />
                            </Dropdown>
                          ))}
                        </>}
                      </div>
                    }
                  />
                {!state.wasSearched && <Loader />}
              </div>
            );
          }}
        </WithSearch>
      </SearchProvider>
    </div>
  );
}
                            // <b>{getLabel(filter.field)} {" : "}</b>
//
                          // <Facet filterType="any" field={'keywords_auteurs'} label={'keywords_auteurs'} isFilterable={true} />
                          // <Facet filterType="any" field="keywords_lieux" label="keywords_lieux" isFilterable={true} />
                          // <Facet filterType="any" field="periodeHistorique" label="periodeHistorique" isFilterable={true} />
