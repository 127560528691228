import React from "react";

export default function Loader() {
  return (
    <div className="loader-wrapper">
      <div className="loader">
        <div></div>
        <div></div>
      </div>
      <div className="loader-label">Chargement en cours...</div>
    </div>
  )
}
