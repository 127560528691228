export default async function runRequest(body) {
  // const url = "https://peinture.nord-nord-ouest.com/api/search";
  const url = "https://peinture.vercel.app/api/search";
  const response = await fetch(url, {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(body)
  });
  return response.json();
}
